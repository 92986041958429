import { useSelector } from "react-redux";
import {
  selectLogo,
  selectLogoHeight
} from "features/configuration/selectors/configuration-selectors";

const DEFAULT_LOGO_HEIGHT = 48;

export const useLogo = () => {
  return {
    logoPath: useSelector(selectLogo),
    logoHeight: useSelector(selectLogoHeight) || DEFAULT_LOGO_HEIGHT
  };
};
