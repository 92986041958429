import styled, { css } from "styled-components";
import {
  getCssForExtraLargeView,
  getCssForLargeView,
  getCssForMobileView,
  getCssForTabletView
} from "styles/responsive";

type MainImage = {
  backgroundImageSet: {
    mobile: string;
    desktop: string;
  };
};

export const MainImageContainer = styled.section<MainImage>`
  width: 100%;
  height: 85vh;
  display: flex;
  background-image: image-set(${props => props.backgroundImageSet.desktop});
  background-repeat: no-repeat;
  background-origin: center;
  background-size: cover;
  background-position: 100% 30%;

  ${props =>
    getCssForMobileView(css`
      background-image: image-set(${props.backgroundImageSet.mobile});
      height: 585px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    `)}

  ${getCssForTabletView(css`
    background-position: 40% 30%;
  `)}
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.grey["800"]};
`;

export const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3rem;

  ${getCssForMobileView(css`
    margin: 0 25px;
    width: 100%;
  `)}

  ${getCssForLargeView(css`
    margin-left: 4rem;
  `)}

  ${getCssForExtraLargeView(css`
    margin-left: 5rem;
  `)}
`;

export const Title = styled.h1`
  margin: 1rem 0 3rem;
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.5rem;

  ${getCssForExtraLargeView(css`
    margin: 1rem 0 3rem;
    font-size: 4rem;
    line-height: 4.5rem;
  `)}

  ${getCssForMobileView(css`
    margin: 1rem 0 2rem;
    font-size: 2rem;
    line-height: 2.5rem;
  `)}
`;

export const CardList = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  width: 353px;

  ${getCssForMobileView(css`
    width: 100%;
  `)}

  ${getCssForLargeView(css`
    width: 378px;
  `)}

  ${getCssForExtraLargeView(css`
    width: 496px;
  `)}
`;

export const LandingMessage = styled.div`
  margin-top: 2.5rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  ${getCssForExtraLargeView(css`
    font-size: 18px;
  `)}
`;
