import styled, { css } from "styled-components";
import CrossIcon from "app/components/icons/cross-icon";
import MenuIcon from "app/components/icons/menu-icon";

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
`;

const Container = css`
  position: relative;
  min-height: 70px;
  max-height: 70px;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
`;

export const FlexContainer = styled.div`
  ${Container}
  justify-content: space-between;
`;

export const FlexContainerExpanded = styled.div`
  ${Container}
  justify-content: flex-end;
`;

export const LeftSection = styled.div`
  display: flex;
  gap: 28px;
  align-items: center;
  justify-content: space-between;
`;

export const MenuLabel = styled.p`
  margin-left: 8px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 600;
  color: ${({ theme }) => theme.blue["300"]};
  cursor: pointer;
`;

const iconCss = css`
  cursor: pointer;
`;

export const MenuIconStyled = styled(MenuIcon)`
  ${iconCss}
`;

export const CrossIconStyled = styled(CrossIcon)`
  ${iconCss}
`;

export const MenuButtonsContainer = styled.button`
  display: flex;
  background: ${({ theme }) => theme.white};
  align-items: center;
`;
