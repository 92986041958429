import styled, { css } from "styled-components";
import { getCssForMobileView } from "styles/responsive";
import { LinkResolver } from "app/components/link-resolver/link-resolver";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  background-color: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.secondaryContrastColor};
  font-family: ${({ theme }) => theme.secondaryFontFamily};
  margin-top: auto;
`;

const FlexColumnMobileStyles = css`
  margin-right: 0px;
  padding: 0 12px;
  flex-direction: column;
`;
export const FlexColumn = styled.div`
  display: flex;
  padding: 20px 12px;
  ${getCssForMobileView(FlexColumnMobileStyles)}
`;

const FooterTitleMobileStyles = css`
  border-bottom: 1px solid ${({ theme }) => theme.secondaryContrastColor}26;
`;
export const FooterTitle = styled.h2`
  text-transform: uppercase;
  font-weight: bold;
  ${getCssForMobileView(FooterTitleMobileStyles)}
`;

const LinkMobileStyles = css`
  border-bottom: 1px solid ${({ theme }) => theme.secondaryContrastColor}26;
  padding: 17px 0;
  border-right: none;
`;
export const Link = styled(LinkResolver)`
  text-decoration: none;
  padding: 0 1rem;
  border-right: 1px solid ${({ theme }) => theme.secondaryContrastColor}40;
  &:last-child {
    border-right: none;
  }
  color: ${({ theme }) => theme.secondaryContrastColor};
  ${getCssForMobileView(LinkMobileStyles)}
`;

const RightsReservedMobileStyles = css`
  border-top: none;
`;
export const RightsReserved = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.secondaryContrastColor}40;
  height: 30px;
  ${getCssForMobileView(RightsReservedMobileStyles)}
`;

const RightsReservedTextMobileStyles = css`
  margin-right: 18px;
`;

export const RightsReservedText = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.secondaryContrastColor}b3;
  margin-right: 5rem;
  ${getCssForMobileView(RightsReservedTextMobileStyles)}
`;

export const FooterListItem = styled.li`
  color: ${({ theme }) => theme.secondaryContrastColor};
  border-inline-end: 1px solid rgba(255, 255, 255, 0.15);
  display: inline;
  padding: 0 1rem;
  &:last-child {
    border-inline-end: none;
  }
  ${getCssForMobileView(css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    border-inline-end: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: 0;
    &:last-child {
      border-bottom: none;
    }
  `)}
`;
