import { useTranslate } from "@customer_interactions/i18n";
import {
  PartnerInformationSection,
  PartnerTitle,
  PartnerSubtitle,
  PartnerSection,
  HubPartnerInformationSection,
  HubPartnerText
} from "./header-partner-section-styles";
import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { selectUseAlternativeHomepage } from "features/configuration/selectors/configuration-selectors";
import { useWindowSize } from "hooks/use-window-size";

export const HeaderPartnerSection = ({ children }: PropsWithChildren<{}>) => {
  const t = useTranslate();
  const isAlternativeHomePage = useSelector(selectUseAlternativeHomepage);
  const { isMobile } = useWindowSize();

  let title = isAlternativeHomePage
    ? t("[hub_header] in_partner_name", true)
    : t("[header] title", true);
  let subtitle = isAlternativeHomePage
    ? t("[hub_header] with_partner_name", true)
    : t("[header] subtitle", true);

  if (isAlternativeHomePage && !isMobile) {
    subtitle = `${title} ${subtitle}`;
    title = "";
  }

  return (
    <PartnerSection>
      {children}
      {(title || subtitle) &&
        (isAlternativeHomePage ? (
          <HubPartnerInformationSection>
            <HubPartnerText>{title}</HubPartnerText>
            <HubPartnerText>{subtitle}</HubPartnerText>
          </HubPartnerInformationSection>
        ) : (
          <PartnerInformationSection>
            <PartnerTitle>{title}</PartnerTitle>
            <PartnerSubtitle>{subtitle}</PartnerSubtitle>
          </PartnerInformationSection>
        ))}
    </PartnerSection>
  );
};
