import { ConfigModel } from "./configuration-types";
import { AXA_LEAD_BRANDING_TYPE } from "./configuration-constants";

const configurationInitialState: ConfigModel = {
  branding_type: AXA_LEAD_BRANDING_TYPE,
  isDebuggable: false,
  isUnderMaintenance: false,
  useAlternativeHomepage: false,
  logo: "/assets/images/my-tc-logo.png",
  logo_height: 48,
  partnerTrackingId: "",
  bingMapsApiKey: "",
  partnerId: "",
  manifestId: "",
  one_trust_data_domain_id: "",
  auth0ClientId: "",
  persistQueryParameters: false,
  auth0Domain: "",
  assistanceServices: [],
  favicon: "/assets/icons/favicon-axa.ico",
  languages_iso_codes: ["en"],
  locale: "en",
  digitalCard: {
    enable: false,
    imageURL: "/assets/images/digital-card-default.png"
  },
  hasContactUsTlfNumber: true,
  hasVideoSection: true,
  navBar: {
    hasGoBackButton: false,
    redirectUrls: {}
  },
  useCustomLanguageInFAQ: false,
  privacyPolicySections: ["legal", "terms_conditions"],
  live_chat: {
    enabled: false,
    widget_id: ""
  }
};

export default configurationInitialState;
