const coBrandedTheme = {
  primaryFontFamily: "SourceSansPro",
  secondaryFontFamily: "SourceSansPro",
  primaryColor: "#4976BA", // ocean 200
  primaryContrastColor: "#FFFFFF",
  secondaryColor: "#F0F0F0", // grey 200
  secondaryContrastColor: "#111B1D" // grey 900
};

export default coBrandedTheme;
